import Vue from 'vue';

require('@/global-components');

import 'bootstrap';

import store from '@/portal/store';
import ShopCartBtn from "@/portal/widgets/shop_cart/ShopCartBtn.vue";
import AddShopCartItemBtn from "@/portal/widgets/shop_cart/AddShopCartItemBtn.vue";

import S from 'swiper';
window.Swiper = S;
// import 'swiper/css';

Vue.prototype.$appConfig = window.appConfig;
store.dispatch('load_user', window.appConfig.user).then(r => new Vue({
    el: '#app',
    store,
    components: {
        ShopCartBtn,
        AddShopCartItemBtn,
    },
    data() {
        return {
            toolbarWindow: null,
        }
    },
    created() {
        this.$http.get('shop-cart', (res) => {
            this.$store.dispatch('initShopCart', res);
        });
    },
    methods: {
        // ...mapActions('shop_cart', ['initShopCart', 'addToCart', 'updateCartItemQuantity', 'removeCartItem']),
        toggleTheme: function () {
            if (this.$store.getters.user.theme === 'dark') {
                this.$store.getters.user.theme = 'default';
            } else {
                this.$store.getters.user.theme = 'dark';
            }
            this.$http.post('profile/change-theme', {theme: this.$store.getters.user.theme}, function (res) {});
        },
    },
}));
