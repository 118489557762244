function getCartItem(state, productId, saleDateId) {
    return saleDateId
        ? state.shop_cart_items.find(item => item.product_id === productId && item.sale_date_id === saleDateId)
        : state.shop_cart_items.find(item => item.product_id === productId && item.sale_date_id === null);
}

function removeCartItem(state, shopCartItem) {
    state.shop_cart_items = shopCartItem.sale_date
        ? state.shop_cart_items.filter(item => !(item.product_id === shopCartItem.product_id && item.sale_date_id === shopCartItem.sale_date_id))
        : state.shop_cart_items.filter(item => !(item.product_id === shopCartItem.product_id && item.sale_date_id === null));
}

export default {
    state: () => ({
        shop_cart_items: [],
    }),
    mutations: {
        initShopCart(state, response) {
            response.shop_cart_items.forEach(function (item) {
                let product = item.product;
                product.shop = response.shops.find(s => s.id === product.shop_id);
                state.shop_cart_items.push({
                    id: item.id,
                    product_id: item.product_id,
                    sale_date_id: item.sale_date_id,
                    quantity: item.quantity,
                    product: product,
                    sale_date: item.sale_date,
                    discount: null,
                });
            });
        },
        saveCartItem(state, shopCartItem) {
            let existingItem = getCartItem(state, shopCartItem.product_id, shopCartItem.sale_date_id);
            if (existingItem) {
                existingItem.product_id = shopCartItem.product_id;
                existingItem.sale_date_id = shopCartItem.sale_date ? shopCartItem.sale_date.id : null;
                existingItem.quantity = shopCartItem.quantity;
            } else {
                state.shop_cart_items.push({
                    id: shopCartItem.id,
                    product_id: shopCartItem.product.id,
                    sale_date_id: shopCartItem.sale_date ? shopCartItem.sale_date.id : null,
                    quantity: shopCartItem.quantity,
                    product: shopCartItem.product,
                    sale_date: shopCartItem.sale_date,
                });
            }
        },
        removeCartItem(state, shopCartItem) {
            removeCartItem(state, shopCartItem);
        },
    },
    actions: {
        initShopCart({ commit }, response) {
            commit('initShopCart', response);
        },
        saveCartItem({ commit }, shopCartItem) {
            commit('saveCartItem', shopCartItem);
        },
        removeCartItem({ commit }, shopCartItem) {
            commit('removeCartItem', shopCartItem);
        },
    },
    getters: {
        getShopCartItems(state) {
            return state.shop_cart_items;
        },
        getShopCartItem: (state) => (productId, saleDateId) => {
            return getCartItem(state, productId, saleDateId);
        },
    },
};
